import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import{graphql} from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Activationinvaild = () => {
  const {t} = useTranslation();
  return(
    <div className="freeTrial">
      <HeardTopWrapper title={t('activationInvaild')} />
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('activationInvaild')}</title>
        </Helmet>
        <PageContainer>
          <div className="text-center">
            <p>{t('activationInvaildDes1')} <a href={`mailto:${process.env.EMAIL_ADDRESS}`}>{t('administrator')}</a>.</p>
          </div>
        </PageContainer>
      </Layout>
    </div>
    
  )
}
export default Activationinvaild
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
